import { EquipmentRoomData, LaboratoriesDataApi, LaboratoryRoomData, PlanLaboratoryDto, RoomData } from "../Api"; // Import the generated API
import { getAuthConfiguration } from "./configuration";

const ApiFactory = () => new LaboratoriesDataApi(getAuthConfiguration());

export const getRoomData = (studyProgramId: string, schoolYear: number): Promise<RoomData[]> => {
    return ApiFactory().apiLaboratoriesDataContentStudyProgramIdSchoolYearGet({studyProgramId, schoolYear});
  };
  
  export const getRoomEquipmentData = (studyProgramId: string, schoolYear: number): Promise<EquipmentRoomData[]> => {
    return ApiFactory().apiLaboratoriesDataEquipmentStudyProgramIdSchoolYearGet({studyProgramId, schoolYear});
  };
  
  export const getLaboratoryCapacity = (studyProgramId: string, schoolYear: number): Promise<LaboratoryRoomData[]> => {
    return ApiFactory().apiLaboratoriesDataCapacityStudyProgramIdSchoolYearGet({studyProgramId, schoolYear});
  };
  
  export const getLaboratories = (studyProgramId: string, schoolYear: number): Promise<PlanLaboratoryDto[]> => {
    return ApiFactory().apiLaboratoriesDataLaboratoriesStudyProgramIdSchoolYearGet({studyProgramId, schoolYear});
  };