import { useState } from "react";
import Layout from "../../../Containers/Layout";
import { Table, Input, Typography } from "antd";
import { useQuery } from "react-query";
import { getRoomEquipmentData } from "../../../Requests/laboratory-data-requests";
import { useParams } from "react-router-dom";
import { getStudyProgram } from "../../../Requests/study-program-request";

const { Title } = Typography;
const { Search } = Input;



const LaboratoriesTable = () => {
  const [searchRoom, setSearchRoom] = useState<string>("");
  const [searchRoomName, setSearchRoomName] = useState<string>("");

  const { programId, schoolYear } = useParams<{ programId: string, schoolYear: string }>();

  const { data: program } = useQuery(
    ["getProgram", programId], 
    () => getStudyProgram(programId)
  );

  const { data, isLoading, error } = useQuery(
      ['getRoomEquipmentData', programId, schoolYear],
      async () => {
          const response = await getRoomEquipmentData(programId, Number(schoolYear));
          return response;
      }
  );
  const equipmentData = data || [];

  const flattenedData = equipmentData
    .map((roomData) =>
      roomData.disciplines?.map((discipline, index) => ({
        key: `${roomData.roomNumber}-${index}`,
        roomNumber: roomData.roomNumber,
        roomName: roomData.roomName,
        disciplinaName: discipline.disciplinaName,
        laboratoryWorks: roomData.laboratoryEquipment?.join(", "),
        isFirstRow: index === 0,
        roomRowSpan: roomData.disciplines?.length,
      }))
    )
    .flat()
    .filter((item): item is NonNullable<typeof item> => item !== undefined);

  const filteredData = flattenedData.filter((item) => {
    const matchesRoom = !searchRoom || item?.roomNumber?.toLowerCase().includes(searchRoom.toLowerCase());
    const matchesRoomName = !searchRoomName || item?.roomName?.toLowerCase().includes(searchRoomName.toLowerCase());
    
    return matchesRoom && matchesRoomName;
  });

  const columns = [
    {
      title: <Search placeholder="Numar" allowClear onSearch={setSearchRoom} />,
      dataIndex: "roomNumber",
      key: "roomNumber",
      render: (text: string, record: any) => {
        const rowSpan = record.isFirstRow ? record.roomRowSpan : 0;
        return {
          children: text,
          props: { rowSpan },
        };
      },
    },
    {
      title: <Search placeholder="Nume" allowClear onSearch={setSearchRoomName} />,
      dataIndex: "roomName",
      key: "roomName",
      render: (text: string, record: any) => {
        const rowSpan = record.isFirstRow ? record.roomRowSpan : 0;
        return {
          children: text,
          props: { rowSpan },
        };
      },
    },
    {
      title: "Nume disciplina",
      dataIndex: "disciplinaName",
      key: "disciplinaName",
    },
    {
      title: "Echipament",
      dataIndex: "laboratoryWorks",
      key: "laboratoryWorks",
      render: (works: string, record: any) => {
        const rowSpan = record.isFirstRow ? record.roomRowSpan : 0;
        const equipmentList = (
          <ul>
            {works.split(", ").map((work, index) => (
              <li key={index}>{work}</li>
            ))}
          </ul>
        );
        return {
          children: equipmentList,
          props: { rowSpan },
        };
      },
    },
  ];

  return (
    <Layout>
      <Title level={3} style={{ textAlign: "left" }}>Echipament laboratoare - {program?.name}</Title>
      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={false}
        bordered
      />
    </Layout>
  );
};

export default LaboratoriesTable;