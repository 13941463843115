/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EquipmentDisciplineData,
    EquipmentDisciplineDataFromJSON,
    EquipmentDisciplineDataFromJSONTyped,
    EquipmentDisciplineDataToJSON,
} from './EquipmentDisciplineData';

/**
 * 
 * @export
 * @interface EquipmentRoomData
 */
export interface EquipmentRoomData {
    /**
     * 
     * @type {string}
     * @memberof EquipmentRoomData
     */
    roomNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentRoomData
     */
    roomName?: string | null;
    /**
     * 
     * @type {Array<EquipmentDisciplineData>}
     * @memberof EquipmentRoomData
     */
    disciplines?: Array<EquipmentDisciplineData> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof EquipmentRoomData
     */
    laboratoryEquipment?: Array<string> | null;
}

export function EquipmentRoomDataFromJSON(json: any): EquipmentRoomData {
    return EquipmentRoomDataFromJSONTyped(json, false);
}

export function EquipmentRoomDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipmentRoomData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roomNumber': !exists(json, 'roomNumber') ? undefined : json['roomNumber'],
        'roomName': !exists(json, 'roomName') ? undefined : json['roomName'],
        'disciplines': !exists(json, 'disciplines') ? undefined : (json['disciplines'] === null ? null : (json['disciplines'] as Array<any>).map(EquipmentDisciplineDataFromJSON)),
        'laboratoryEquipment': !exists(json, 'laboratoryEquipment') ? undefined : json['laboratoryEquipment'],
    };
}

export function EquipmentRoomDataToJSON(value?: EquipmentRoomData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roomNumber': value.roomNumber,
        'roomName': value.roomName,
        'disciplines': value.disciplines === undefined ? undefined : (value.disciplines === null ? null : (value.disciplines as Array<any>).map(EquipmentDisciplineDataToJSON)),
        'laboratoryEquipment': value.laboratoryEquipment,
    };
}

