import { useState } from "react";
import { Form, Input, Select, Row, Col, Spin } from "antd";
import Layout from "../../../Containers/Layout";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getSubject, updateSubject, addSubject } from "../../../Requests/subject-requests";
import { NOTIFICATION_TYPES, openNotification } from "../../Notifications/NotificationsUtils";
import { getUserId, useIsTitular } from "../../../utils/utilFunctions";
import { theme } from "../../../theme";
import ConfirmationModal from "../../../Containers/ConfirmationModal";
import CustomEditor from "../../../CustomComponents/CustomEditor";
import SubjectContentForm from "./SubjectCursContentForm";
import SubjectEvaluationForm from "./SubjectLaboratoryContentForm";
import { AcademicUser, AddSubjectFileDTO, EvaluationTypeEnum, GradingTypeEnum, IndicatorTypeEnum, Laboratory, SemesterTypeEnum, StudyCycleEnum, StudyPlanDTO, StudyProgram, StudyYearEnum, SubjectContent, SubjectEvaluation, TeachingTypeEnum } from "../../../Api";
import styles from "./SubjectForm.module.scss";
import { getStudyProgram } from "../../../Requests/study-program-request";
import useDebounce from "../../../Hooks/debounce";
import { getAllAcademicUsers } from "../../../Requests/academic-user-requests";
import { getDepartmentsForFaculty } from "../../../Requests/faculty-requests";
import { useBlockedByRole, useBlockedByStatus } from "../helpers";

const { Option } = Select;

const SubjectForm = (props: any) => {
  const { t } = useTranslation();
  const currentUserId = getUserId();
  const { subjectId } = useParams<{ subjectId: string }>();
  const [redirect, setRedirect] = useState(false);
  const { programId, studyCycle } = useParams<{ programId: string, studyCycle: StudyCycleEnum }>();

  const location = useLocation();

  const plan = location.state as StudyPlanDTO;

  const isTitular = useIsTitular(subjectId);
  const blockedByRole = useBlockedByRole(plan?.departmentId!);

  const disabledByStatus = useBlockedByStatus(subjectId);

  const [searchTermNameCourse, setCourseUserSearchTerm] = useState('');
	const debouncedCourseUserSearch = useDebounce(searchTermNameCourse, 1000);

  const [searchTermNameLab, setLabUserSearchTerm] = useState('');
	const debouncedLabUserSearch = useDebounce(searchTermNameLab, 1000);
  
  const [form] = Form.useForm<{
    nameRO: string | null;
    nameEN: string | null;
    departmentId: string | null;
    semester: SemesterTypeEnum;
    studyYear: StudyYearEnum;
    schoolYear: number;
    studyProgramId: string; 
    evaluationType: EvaluationTypeEnum;
    subjectType: IndicatorTypeEnum;
    subjectCategory: IndicatorTypeEnum;
    code: string | null;
    gradingType: GradingTypeEnum;
    totalWeeklyHours: number;
    courseWeeklyHours: number;
    labWeeklyHours: number;
    seminarWeeklyHours: number;
    selfStudyHours: number;
    tutoringHours: number;
    examHours: number;
    otherActivitiesHours: number;
    totalHours?: number; 
    courseHours?: number;
    labHours?: number;
    totalSelfStudyHours?: number;
    totalSemesterHours?: number;
    credits?: number;
    curriculumPrecondition: string | null;
    respultsPrecondition: string | null;
    courseConditions: string | null;
    labConditions: string | null;
    generalObjective: string | null;
    specificCompetence: string | null;
    generalCompetence: string | null;
    teachingKnowledges: string | null;
    teachingAptitudes: string | null;
    teachingResponsabilities: string | null;
    teachingMethods: string | null;
    courseBibliography: string | null;
    labBibliography: string | null;
    promotionConditions: string | null;
    subjectCoroboration: string | null;
    studyProgram: StudyProgram; 
    courseCoordinatorIds: string[] | null;
    labCoordinatorIds: string[] | null;
    laboratories: Laboratory[] | null;
    courseContents: SubjectContent[];
    labContents: SubjectContent[]; 
    courseEvaluations: SubjectEvaluation[];
    labEvaluations: SubjectEvaluation[];
  }>();

  const openGetErrorNotification = (_error: any) => {
    openNotification(
      'Eroare',
      'Nu s-au putut prelua datele de pe server!',
      NOTIFICATION_TYPES.ERROR
    );
  };

  const { data: courseUsers, isLoading: isLoadingCourseSearch } = useQuery(['getAcademiUsersCourse', debouncedCourseUserSearch],
    () => {
        const response = getAllAcademicUsers(debouncedCourseUserSearch, 1, 10, undefined);
        return response;
    },
    {
        onError: openGetErrorNotification,
    }
  );

  const { data: labUsers, isLoading: isLoadingLabSearch } = useQuery(['getAcademiUsersLab', debouncedLabUserSearch],
    () => {
        const response = getAllAcademicUsers(debouncedLabUserSearch, 1, 10, undefined);
        return response;
    },
    {
        onError: openGetErrorNotification,
    }
  );

  

  const { data: program } = useQuery( //TODO error handling
    ["getProgram", programId], 
    () => getStudyProgram(programId)
  );
  
  const { data: departments } = useQuery(
    ['getDepartments'],
    () => {
      const faculties = program?.facultyId ? [program.facultyId] : [];
      return getDepartmentsForFaculty(faculties);
    },
    {
      enabled: !!program?.facultyId,
      onError: openGetErrorNotification,
    }
  );

  const { data: subject } = useQuery(["getSubject", subjectId], () => getSubject(subjectId), {
    onError: (err) => {
      openGetErrorNotification(err);
    },
    onSuccess: (data) => {
      if (data) {
        const courseContents = data.subjectContents?.filter(content => content.teachingType === TeachingTypeEnum.Course);
        const labContents = data.subjectContents?.filter(content => content.teachingType === TeachingTypeEnum.Lab);

        const courseEvaluations = data.subjectEvaluations?.filter(content => content.teachingType === TeachingTypeEnum.Course);
        const labEvaluations = data.subjectEvaluations?.filter(content => content.teachingType === TeachingTypeEnum.Lab);
        
        form.setFieldsValue({
          nameRO: data.nameRO,
          nameEN: data.nameEN,
          departmentId: data?.departmentId,
          semester: data.semester,
          studyYear: data.studyYear,
          schoolYear: data.schoolYear,
          studyProgramId: data.studyProgramId,
          evaluationType: data.evaluationType,
          subjectType: data.subjectType,
          subjectCategory: data.subjectCategory,
          code: data.code,
          gradingType: data.gradingType,
          totalWeeklyHours: data.totalWeeklyHours,
          courseWeeklyHours: data.courseWeeklyHours,
          labWeeklyHours: data.labWeeklyHours,
          seminarWeeklyHours: data.seminarWeeklyHours,
          totalHours: data.totalHours,
          courseHours: data.courseHours,
          labHours: data.labHours,
          selfStudyHours: data.selfStudyHours,
          tutoringHours: data.tutoringHours,
          examHours: data.examHours,
          otherActivitiesHours: data.otherActivitiesHours,
          totalSelfStudyHours: data.totalSelfStudyHours,
          totalSemesterHours: data.totalSemesterHours,
          credits: data.credits,
          curriculumPrecondition: data.curriculumPrecondition,
          respultsPrecondition: data.respultsPrecondition,
          courseConditions: data.courseConditions,
          labConditions: data.labConditions,
          generalObjective: data.generalObjective,
          specificCompetence: data.specificCompetence,
          generalCompetence: data.generalCompetence,
          teachingKnowledges: data.teachingKnowledges,
          teachingAptitudes: data.teachingAptitudes,
          teachingResponsabilities: data.teachingResponsabilities,
          teachingMethods: data.teachingMethods,
          courseBibliography: data.courseBibliography,
          labBibliography: data.labBibliography,
          promotionConditions: data.promotionConditions,
          subjectCoroboration: data.subjectCoroboration,
          studyProgram: data.studyProgram,
          courseCoordinatorIds: data.courseCoordinators?.map((user: AcademicUser) => user.id || '') || [],
          labCoordinatorIds: data.labCoordinators?.map((user: AcademicUser) => user.id || '') || [],
          laboratories: data.laboratories,
          courseContents,
          labContents, 
          courseEvaluations,
          labEvaluations,
        });
      }
    },
    enabled: !!subjectId,
    refetchOnWindowFocus: false
  });

  //TODO cleanup error and success message functions
  const openSaveErrorNotification = (error: any) => {
    openNotification(
      'Eroare!',
      'Nu s-a putut salva disciplina!',
      NOTIFICATION_TYPES.ERROR
    );
  };

  const handleCancel = () => {
    setRedirect(true);
  };

  const openSuccessNotificationAndRedirect = () => {
    setRedirect(true);
    openNotification(
      'Succes!',
      'Disciplina a fost salvată cu succes!',
      NOTIFICATION_TYPES.SUCCESS,
      6
    );
  };

  const handleSave = () => {
    const values = form.getFieldsValue();
    if (values.nameRO && values.studyYear) {
      const subjectData: AddSubjectFileDTO = {
        nameRO: values.nameRO,
        nameEN: values.nameEN,
        departmentId: values.departmentId || '',
        semester: values.semester,
        studyYear: values.studyYear,
        studyProgramId: program?.id,
        evaluationType: values.evaluationType,
        subjectType: values.subjectType,
        subjectCategory: values.subjectCategory,
        code: values.code,
        gradingType: values.gradingType,
        totalWeeklyHours: values.totalWeeklyHours,
        courseWeeklyHours: values.courseWeeklyHours,
        labWeeklyHours: values.labWeeklyHours,
        seminarWeeklyHours: values.seminarWeeklyHours,
        totalHours: values.totalHours,
        courseHours: values.courseHours,
        labHours: values.labHours,
        selfStudyHours: values.selfStudyHours, 
        tutoringHours: values.tutoringHours,
        examHours: values.examHours,
        otherActivitiesHours: values.otherActivitiesHours,
        totalSelfStudyHours: values.totalSelfStudyHours,
        totalSemesterHours: values.totalSemesterHours, 
        credits: values.credits, 
        curriculumPrecondition: values.curriculumPrecondition,
        respultsPrecondition: values.respultsPrecondition, 
        courseConditions: values.courseConditions, 
        labConditions: values.labConditions,
        generalObjective: values.generalObjective,
        specificCompetence: values.specificCompetence,
        generalCompetence: values.generalCompetence,
        teachingKnowledges: values.teachingKnowledges,
        teachingAptitudes: values.teachingAptitudes,
        teachingResponsabilities: values.teachingResponsabilities,
        teachingMethods: values.teachingMethods,
        subjectContents: [...(values.courseContents.map(e => ({ ...e, teachingType: TeachingTypeEnum.Course }))), ...(values.labContents.map(e => ({ ...e, teachingType: TeachingTypeEnum.Lab })))],
        courseBibliography: values.courseBibliography,
        labBibliography: values.labBibliography,
        subjectEvaluations: [...(values.courseEvaluations.map(e => ({ ...e, teachingType: TeachingTypeEnum.Course }))), ...(values.labEvaluations.map(e => ({ ...e, teachingType: TeachingTypeEnum.Lab })))],
        promotionConditions: values.promotionConditions,
        subjectCoroboration: values.subjectCoroboration,
        courseCoordinatorIds: values.courseCoordinatorIds,
        labCoordinatorIds: values.labCoordinatorIds,
      };
      
      const saveAction = subjectId ? updateSubject(subjectId, subjectData) : addSubject(subjectData);

      saveAction.then(openSuccessNotificationAndRedirect).catch(openSaveErrorNotification);
    } else {
      openNotification(
        'Eroare!',
        'Vă rugăm să completați toate câmpurile obligatorii!',
        NOTIFICATION_TYPES.ERROR
      );
    }
  };

  
  if (redirect) {
    return <Redirect to={{ pathname: '/' + studyCycle + '/plan/' + program?.id}}></Redirect>;
  }
  else {
    return (
    <Layout>
      <div className="container">
        <Form form={form} layout="vertical">
           <Row gutter={16}><Col span={24}><span style={{ fontSize: '16px', fontWeight: 'bold', display: 'flex' }}>1. Date despre program</span></Col></Row>
          <div style={{
            border: "1px solid lightgrey", 
            borderRadius: "5px", 
            overflow: "hidden",
          }}>
            
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={10} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>1.1 Instituția de învățământ superior</span>
              </Col>
              <Col span={14} style={{ padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>Universitatea Națională de Știință și Tehnologie Politehnica București</span>
              </Col>
            </Row>
            
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={10} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>1.2 Facultatea</span>
              </Col>
              <Col span={14} style={{ padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>{program?.faculty?.name}</span>
              </Col>
            </Row>

            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={10} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>1.3 Departamentul</span>
              </Col>
              <Col span={14} style={{ padding: "8px 16px" }}>
                <Form.Item name="departmentId"  >
                <Select
                  disabled
                  className={styles.antSelect}
                  style={{ width: "100%", textAlign: "left" }}
                  placeholder={"Department"}>
                      {departments?.map((department) => (
                        <Option key={department.id} value={department.id}>
                            {department.departmentNameRo}
                        </Option>
                      ))}
                  </Select>
              </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={10} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>1.4 Domeniul de studii</span>
              </Col>
              <Col span={14} style={{ padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>{program?.domainName}</span>
              </Col>
            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={10} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>1.5 Ciclul de studii</span>
              </Col>
              <Col span={14} style={{ padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>{ studyCycle === StudyCycleEnum.Bachelor ? "Licență" : "Master"}</span>
              </Col>
            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={10} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>1.6 Specializarea</span>
              </Col>
              <Col span={14} style={{ padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>{program?.name}</span>
              </Col>
            </Row>
          </div>


          <Row gutter={16}><Col span={24}><span style={{ fontSize: '16px', fontWeight: 'bold', display: 'flex', marginTop: "15px" }}>2. Date despre disciplină</span></Col></Row>
          <div style={{
            border: "1px solid lightgrey", 
            borderRadius: "5px", 
            overflow: "hidden",
          }}>
            
            <Row gutter={16}   style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={10} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>2.1 Denumirea disciplinei (ro)(en)</span>
              </Col>
              <Col span={14} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="nameRO"
              >
                <Input disabled placeholder="Numele disciplinei RO" />
                
              </Form.Item>

              <Form.Item
                name="nameEN"
              >
                <Input disabled placeholder="Numele disciplinei EN" />
              </Form.Item>
              </Col>
            </Row>
            
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={10} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>2.2 Titularul activităților de curs</span>
              </Col>
              <Col span={14} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="courseCoordinatorIds"
              >
                <Select
                            className={styles.antSelect}
                            disabled={blockedByRole}
                            mode="multiple"
                            onSelect={() => setLabUserSearchTerm('')}
                            maxTagCount={10}
                            allowClear
                            showSearch
                            style={{ width: "100%", textAlign: "left" }}
                            placeholder={"Căutare titular curs"}
                            filterOption={false}
                            onSearch={setCourseUserSearchTerm}
                            notFoundContent={
                              isLoadingCourseSearch? <Spin size="small" /> : null
                            }
                        >
                            {[...(subject?.courseCoordinators || []),
                              ...courseUsers?.data?.filter(user => !(subject?.courseCoordinators || []).find(u => u.id === user.id)) || []]
                                ?.map((user) => (
                                    <Option key={user.id} value={user.id}>
                                        {user.name}
                                    </Option>
                                ))
                                }
                        </Select>
              </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={10} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>2.3 Titularul activităților de seminar / laborator</span>
              </Col>
              <Col span={14} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="labCoordinatorIds"
              >
                <Select
                            className={styles.antSelect}
                            mode="multiple"
                            onSelect={() => setLabUserSearchTerm('')}
                            maxTagCount={10}
                            allowClear
                            showSearch
                            style={{ width: "100%", textAlign: "left" }}
                            placeholder={"Căutare titular laborator"}
                            filterOption={false}
                            onSearch={setLabUserSearchTerm}
                            notFoundContent={
                                isLoadingLabSearch ? <Spin size="small" /> : null
                            }
                        >
                            {[...(subject?.labCoordinators || []),
                              ...labUsers?.data?.filter(user => !(subject?.labCoordinators || []).find(u => u.id === user.id)) || []]
                                ?.map((user) => (
                                    <Option key={user.id} value={user.id}>
                                        {user.name}
                                    </Option>
                                ))
                                }
                        </Select>
              </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={3} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>2.4 Anul de studiu</span>
              </Col>
              <Col span={3} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="studyYear"
              >
                <Select disabled style={{ width: "100%" }} placeholder="Selectați anul de studii">
                  {studyCycle === "Bachelor" && (
                    <>
                      <Option value="L1">L1</Option>
                      <Option value="L2">L2</Option>
                      <Option value="L3">L3</Option>
                      <Option value="L4">L4</Option>
                    </>
                  )}
                  
                  {studyCycle === "Master" && (
                    <>
                      <Option value="M1">M1</Option>
                      <Option value="M2">M2</Option>
                    </>
                  )} 
                </Select>
              </Form.Item>
              </Col>

              <Col span={3} style={{ borderRight: "1px solid lightgrey", borderLeft: "1px solid lightgrey",  padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>2.5 Semestrul</span>
              </Col>
              <Col span={3} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="semester"
              >
                <Select disabled style={{width: "100%"}} placeholder="Selectați semestrul">
                  <Option value="S1">Semestrul 1</Option>
                  <Option value="S2">Semestrul 2</Option>
                </Select>
              </Form.Item>
              </Col>

              <Col span={3} style={{ borderRight: "1px solid lightgrey", borderLeft: "1px solid lightgrey",  padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>2.6. Tipul de evaluare</span>
              </Col>
              <Col span={3} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="evaluationType"
              >
                <Select disabled style={{width: "100%"}} placeholder="Selectați tipul de evaluare">
                  <Option value="E">Examen</Option>
                  <Option value="V">Verificare</Option>
                </Select>
              </Form.Item>
              </Col>
              
              <Col span={3} style={{ borderRight: "1px solid lightgrey", borderLeft: "1px solid lightgrey",  padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>2.7 Regimul disciplinei</span>
              </Col>
              <Col span={3} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="subjectType"
              >
                <Select disabled style={{ width: "100%" }} placeholder="Selectați regimul disciplinei">
                  <Option value="Mandatory">Obligatorie</Option>
                  <Option value="Optional">Opțională</Option>
                  <Option value="Facultative">Facultativă</Option>
                </Select>
              </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={6} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>2.8 Tipul disciplinei</span>
              </Col>
              <Col span={3} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="subjectCategory"
              >
                <Select disabled style={{ width: "100%" }} placeholder="Selectați tipul disciplinei">
                  <Option value="Fundamental">Fundamental</Option>
                  <Option value="Specialty">Specialitate</Option>
                  <Option value="Domain">Domeniu</Option>
                  <Option value="Complementary">Complementară</Option>
                </Select>
              </Form.Item>
              </Col>

              <Col span={3} style={{ borderRight: "1px solid lightgrey", borderLeft: "1px solid lightgrey",  padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>2.9 Codul disciplinei</span>
              </Col>
              <Col span={6} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="code"
              >
                <Input disabled/>
              </Form.Item>
              </Col>

              <Col span={3} style={{ borderRight: "1px solid lightgrey", borderLeft: "1px solid lightgrey",  padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%' }}>2.10 Tipul de notare</span>
              </Col>
              <Col span={3} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="gradingType"
              >
                <Select disabled style={{width: "100%"}} placeholder="Selectați tipul de notare">
                  <Option value="Grade">Nota</Option>
                  <Option value="AdmRej">Admis/Respins</Option>
                </Select>
              </Form.Item>
              </Col>
            </Row>
          </div>

          <Row gutter={16}><Col span={24}><span style={{ fontSize: '16px', fontWeight: 'bold', display: 'flex', marginTop: "15px" }}>3. Timpul total estimat (ore pe semestru al activităților didactice)</span></Col></Row>
          <div style={{
            border: "1px solid lightgrey", 
            borderRadius: "5px", 
            overflow: "hidden",
          }}>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={4} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>3.1 Număr de ore pe săptămână</span>
              </Col>
              <Col span={4} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="totalWeeklyHours"
              >
                <Input disabled={disabledByStatus}/>
              </Form.Item>
              </Col>

              <Col span={4} style={{ borderRight: "1px solid lightgrey", borderLeft: "1px solid lightgrey",  padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>Din care: 3.2 curs</span>
              </Col>
              <Col span={4} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="courseWeeklyHours"
              >
                <Input disabled={disabledByStatus}/>
              </Form.Item>
              </Col>

              <Col span={4} style={{ borderRight: "1px solid lightgrey", borderLeft: "1px solid lightgrey",  padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>3.3 seminar<br/><br/>laborator</span>
              </Col>
              <Col span={4} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="seminarWeeklyHours"
              >
                <Input disabled={disabledByStatus}/>
              </Form.Item>
              <Form.Item
                name="labWeeklyHours"
              >
                <Input disabled={disabledByStatus}/>
              </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={4} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>3.4 Total ore din planul de învățământ</span>
              </Col>
              <Col span={4} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="totalHours"
              >
                <Input disabled={disabledByStatus}/>
              </Form.Item>
              </Col>

              <Col span={4} style={{ borderRight: "1px solid lightgrey", borderLeft: "1px solid lightgrey",  padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>Din care: 3.5 curs</span>
              </Col>
              <Col span={4} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="courseHours"
              >
                <Input disabled={disabledByStatus}/>
              </Form.Item>
              </Col>

              <Col span={4} style={{ borderRight: "1px solid lightgrey", borderLeft: "1px solid lightgrey",  padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>3.6 seminar/laborator</span>
              </Col>
              <Col span={4} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="labHours"
              >
                <Input disabled={disabledByStatus}/>
              </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={20} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>Distribuția fondului de timp:</span>
              </Col>
              <Col span={4} style={{ padding: "8px 16px" }}>
              <span>
                ore
              </span>
              </Col>
            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={20} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>Studiul după manual, suport de curs, bibliografie și notițe
Documentare suplimentară în bibliotecă, pe platformele electronice de specialitate
Pregătire seminarii/ laboratoare/proiecte, teme, referate, portofolii și eseuri</span>
              </Col>
              <Col span={4} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="selfStudyHours"
              >
                <Input disabled={disabledByStatus}/>
              </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={20} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>Tutorat</span>
              </Col>
              <Col span={4} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="tutoringHours"
              >
                <Input disabled={disabledByStatus}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={20} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>Examinări</span>
              </Col>
              <Col span={4} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="examHours"
              >
                <Input disabled={disabledByStatus}/>
              </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={20} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>Alte activități (dacă există):</span>
              </Col>
              <Col span={4} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="otherActivitiesHours"
              >
                <Input disabled={disabledByStatus}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={20} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>3.7 Total ore studiu individual (Suma orelor distribuției fondului de timp trebuie sa fie egală cu această valoare)</span>
              </Col>
              <Col span={4} style={{ padding: "8px 16px" }}> 
              <Form.Item
                name="totalSelfStudyHours" 
              >
                <Input disabled={disabledByStatus}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={20} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>3.8 Total ore pe semestru</span>
              </Col>
              <Col span={4} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="totalSemesterHours"
              >
                <Input disabled={disabledByStatus}/>
              </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={20} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>3.9 Numărul de credite</span>
              </Col>
              <Col span={4} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="credits"
              >
                <Input disabled={disabledByStatus}/>
                </Form.Item>
              </Col>
            </Row>
          </div>
          
          <Row gutter={16}><Col span={24}><span style={{ fontSize: '16px', fontWeight: 'bold', display: 'flex', marginTop: "15px" }}>4. Precondiții (acolo unde este cazul)</span></Col></Row>
          <div style={{
            border: "1px solid lightgrey", 
            borderRadius: "5px", 
            overflow: "hidden",
          }}>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={4} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>4.1 de curriculum</span>
              </Col>
              <Col span={20} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="curriculumPrecondition"
              >
              <CustomEditor disabled={disabledByStatus}
                            onEditorChange={(curriculumPrecondition: string) => {
                                form.setFieldsValue({
                                  ...form.getFieldsValue(),
                                    curriculumPrecondition,
                                } as any);
                            }}
                        />
              </Form.Item>         
              </Col>
            </Row>

            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={4} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>4.2 de rezultate ale învățării</span>
              </Col>
              <Col span={20} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="respultsPrecondition"
              >
              <CustomEditor disabled={disabledByStatus}
                            onEditorChange={(respultsPrecondition: string) => {
                                form.setFieldsValue({
                                    ...form.getFieldsValue(),
                                    respultsPrecondition,
                                } as any);
                            }}
                        />
              </Form.Item>
              </Col>
            </Row>
          </div>

          <Row gutter={16}><Col span={24}><span style={{ fontSize: '16px', fontWeight: 'bold', display: 'flex', marginTop: "15px" }}>5. Condiții necesare pentru desfășurarea optimă a activităților didactice (acolo unde este cazul)</span></Col></Row>
          <div style={{
            border: "1px solid lightgrey", 
            borderRadius: "5px", 
            overflow: "hidden",
          }}>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={4} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>5.1 Curs</span>
              </Col>
              <Col span={20} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="courseConditions"
              >
              <CustomEditor disabled={disabledByStatus}
                            onEditorChange={(courseConditions: string) => {
                                form.setFieldsValue({
                                    ...form.getFieldsValue(),
                                    courseConditions,
                                } as any);
                            }}
                        />
              </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={4} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>5.2 Seminar/Laborator/Proiect</span>
              </Col>
              <Col span={20} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="labConditions"
              >
              <CustomEditor disabled={disabledByStatus}
                            onEditorChange={(labConditions: string) => {
                                form.setFieldsValue({
                                    ...form.getFieldsValue(),
                                    labConditions,
                                } as any);
                            }}
                        />
              </Form.Item>
              </Col>
            </Row>
          </div>

          <Row gutter={16}><Col span={24}><span style={{ fontSize: '16px', display: 'flex', justifyContent: "flex-start", flexWrap: "wrap", marginTop: "15px" }}><b>6. Obiectiv general</b><i> (Se referă la intențiile profesorilor pentru studenți, la ceea ce studenții vor fi învățaţi în timpul cursului. 
            Oferă o orientare cu privire la locul cursului în cadrul domeniului științific abordat, precum și la rolul pe care acesta îl are în cadrul specializării studiate. 
            Vor fi descrise de o manieră generală tematicile abordate, justificarea includerii cursului în planul de învățământ al specializării studiate etc.)</i></span></Col></Row>
          <div style={{
            border: "1px solid lightgrey", 
            borderRadius: "5px", 
            overflow: "hidden",
          }}>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              
              <Col span={24} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="generalObjective"
              >
              <CustomEditor disabled={disabledByStatus}
                            onEditorChange={(generalObjective: string) => {
                                form.setFieldsValue({
                                    ...form.getFieldsValue(),
                                    generalObjective,
                                } as any);
                            }}
                        />
              </Form.Item>          
              </Col>
            </Row>

            
          </div>

          <Row gutter={16}><Col span={24}>
            <span style={{ fontSize: '16px', display: 'flex', justifyContent: "flex-start", flexWrap: "wrap", marginTop: "15px" }}><b>7. Competențe</b><i> (Capacitatea dovedită de a utiliza cunoștințe, aptitudini și abilități personale, 
              sociale și/sau metodologice în situații de muncă sau de studiu și pentru dezvoltarea profesională și personală. Reflectă cerințele angajatorilor.)
            </i></span></Col></Row>
          <div style={{
            border: "1px solid lightgrey", 
            borderRadius: "5px", 
            overflow: "hidden",
          }}>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={4} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>Specifice</span>
              </Col>
              <Col span={20} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="specificCompetence"
              >
              <CustomEditor disabled={disabledByStatus}
                            onEditorChange={(specificCompetence: string) => {
                                form.setFieldsValue({
                                    ...form.getFieldsValue(),
                                    specificCompetence,
                                } as any);
                            }}
                        />
              </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={4} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>Transversale (generale)</span>
              </Col>
              <Col span={20} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="generalCompetence"
              >
              <CustomEditor disabled={disabledByStatus}
                            onEditorChange={(generalCompetence: string) => {
                                form.setFieldsValue({
                                    ...form.getFieldsValue(),
                                    generalCompetence,
                                } as any);
                            }}
                        />
              </Form.Item>          
              </Col>
            </Row>
          </div>

          <Row gutter={16}><Col span={24}>
            <span style={{ fontSize: '16px', display: 'flex', justifyContent: "flex-start", flexWrap: "wrap", marginTop: "15px" }}><b>8. Rezultatele învățării</b><i> (Sunt enunțuri sintetice referitoare la ceea ce un student va fi capabil să facă sau să demonstreze la finalizarea unui curs. Rezultatele învățării reflectă realizările studentului și mai puțin intențiile profesorului. Rezultatele învățării informează studenții despre ceea ce se așteaptă de la ei din punct de vedere al performanței, pentru a obține notele și creditele dorite. Sunt definite în termeni concreți, folosind verbe similare exemplelor de mai jos și indică ceea ce se va urmări prin evaluare. 
              Rezultatele învățării vor fi astfel redactate încât să fie evidențiată clar relația față de competențele definite la punctul 7.)
            </i></span></Col></Row>
          <div style={{
            border: "1px solid lightgrey", 
            borderRadius: "5px", 
            overflow: "hidden",
          }}>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={4} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>Cunoștințe</span>
              </Col>
              <Col span={20} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="teachingKnowledges"
              >
              <CustomEditor disabled={disabledByStatus}
                            onEditorChange={(teachingKnowledges: string) => {
                                form.setFieldsValue({
                                    ...form.getFieldsValue(),
                                    teachingKnowledges,
                                } as any);
                            }}
                        />
              </Form.Item>          
              </Col>
            </Row>

            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={4} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>Aptitudini</span>
              </Col>
              <Col span={20} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="teachingAptitudes"
              >
              <CustomEditor disabled={disabledByStatus}
                            onEditorChange={(teachingAptitudes: string) => {
                                form.setFieldsValue({
                                    ...form.getFieldsValue(),
                                    teachingAptitudes,
                                } as any);
                            }}
                        />
              </Form.Item>
              </Col>

            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={4} style={{ borderRight: "1px solid lightgrey", padding: "8px 16px" }}>
                <span style={{ display: 'flex', alignItems: 'left', height: '100%' }}>Responsabilitate și autonomie</span>
              </Col>
              <Col span={20} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="teachingResponsabilities"
              >
              <CustomEditor disabled={disabledByStatus}
                            onEditorChange={(teachingResponsabilities: string) => {
                                form.setFieldsValue({
                                    ...form.getFieldsValue(),
                                    teachingResponsabilities,
                                } as any);
                            }}
                        />
              </Form.Item>
              </Col>
            </Row>
          </div>
          
          <Row gutter={16}><Col span={24}>
            <span style={{ fontSize: '16px', display: 'flex', justifyContent: "flex-start", flexWrap: "wrap", marginTop: "15px" }}><b>9. Metode de predare</b><i> (Se vor avea în vedere metode care să asigure predarea centrată pe student. 
              Se va descrie modul în care se asigură participarea studenților la stabilirea propriului parcurs de învățare, cum se identifică eventualele rămâneri în urmă și ce măsuri remediale se adoptă în astfel de cazuri.)
            </i></span></Col></Row>
          <div style={{
            border: "1px solid lightgrey", 
            borderRadius: "5px", 
            overflow: "hidden",
          }}>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={24} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="teachingMethods"
              >
              <CustomEditor disabled={disabledByStatus}
                            onEditorChange={(teachingMethods: string) => {
                                form.setFieldsValue({
                                    ...form.getFieldsValue(),
                                    teachingMethods,
                                } as any);
                            }}
                        />
              </Form.Item>
              </Col>
            </Row>
          </div>
          
          <Row gutter={16}><Col span={24}>
            <span style={{ fontSize: '16px', display: 'flex', justifyContent: "flex-start", flexWrap: "wrap", marginTop: "15px" }}><b>10. Conținuturi</b></span></Col></Row>
          <div style={{
            border: "1px solid lightgrey", 
            borderRadius: "5px", 
            overflow: "hidden",
          }}>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey", textAlign: "left" }}>
              <Col span={24} style={{ padding: "8px 16px" }}><b>CURS</b></Col>
            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={24} style={{ padding: "8px 16px" }}>
              
              <SubjectContentForm disabled={disabledByStatus}
                            name="courseContents"
                            initialValue={[{
                              nrCrt: 1,
                              content: "",
                              hoursNumber: 0
                            }]}
                            rules={true}
                        
                        ></SubjectContentForm>
              
              </Col>
            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey", textAlign: "left" }}>
              <Col span={24} style={{ padding: "8px 16px" }}><b>Bibliografie:</b></Col>
            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={24} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="courseBibliography"
              >
              <CustomEditor disabled={disabledByStatus}
                            onEditorChange={(courseBibliography: string) => {
                                form.setFieldsValue({
                                    ...form.getFieldsValue(),
                                    courseBibliography,
                                } as any);
                            }}
                        />
              </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey", textAlign: "left" }}>
              <Col span={24} style={{ padding: "8px 16px" }}><b>LABORATOR</b></Col>
            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={24} style={{ padding: "8px 16px" }}>
              
              <SubjectContentForm disabled={disabledByStatus}
                            name="labContents"
                            initialValue={[{
                              nrCrt: 1,
                              content: "",
                              hoursNumber: 0
                            }]}
                            rules={true}
                        
                        ></SubjectContentForm>
              
              </Col>
            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey", textAlign: "left" }}>
              <Col span={24} style={{ padding: "8px 16px" }}><b>Bibliografie:</b></Col>
            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={24} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="labBibliography"
              >
              <CustomEditor disabled={disabledByStatus}
                            onEditorChange={(labBibliography: string) => {
                                form.setFieldsValue({
                                    ...form.getFieldsValue(),
                                    labBibliography,
                                } as any);
                            }}
                        />
              </Form.Item>
              </Col>
            </Row>
          </div>

          <Row gutter={16}><Col span={24}>
            <span style={{ fontSize: '16px', display: 'flex', justifyContent: "flex-start", flexWrap: "wrap", marginTop: "15px" }}><b>11. Evaluare</b></span></Col></Row>
          <div style={{
            border: "1px solid lightgrey", 
            borderRadius: "5px", 
            overflow: "hidden",
          }}>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey", textAlign: "left" }}>
              <Col span={24} style={{ padding: "8px 16px" }}><b>CURS</b></Col>
            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={24} style={{ padding: "8px 16px" }}>             
              <SubjectEvaluationForm disabled={disabledByStatus}
                            name="courseEvaluations"
                            initialValue={[{
                              nrCrt: 1,
                              content: "",
                              hoursNumber: 0
                            }]}
                            rules={true}
                        
                        ></SubjectEvaluationForm>
              </Col>
            </Row>

            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey", textAlign: "left" }}>
              <Col span={24} style={{ padding: "8px 16px" }}><b>Seminar/laborator/proiect</b></Col>
            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={24} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="category"
              >
              <SubjectEvaluationForm disabled={disabledByStatus}
                            name="labEvaluations"
                            initialValue={[{
                              nrCrt: 1,
                              content: "",
                              hoursNumber: 0
                            }]}
                            rules={true}
                        
                        ></SubjectEvaluationForm>
              </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey", textAlign: "left" }}>
              <Col span={24} style={{ padding: "8px 16px" }}><b>11.6 Condiții de promovare</b></Col>
            </Row>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={24} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="promotionConditions"
              >
              <CustomEditor disabled={disabledByStatus}
                            onEditorChange={(promotionConditions: string) => {
                                form.setFieldsValue({
                                    ...form.getFieldsValue(),
                                    promotionConditions,
                                } as any);
                            }}
                        />
              </Form.Item>
              </Col>
            </Row>
          </div>

          <Row gutter={16}><Col span={24}>
            <span style={{ fontSize: '16px', display: 'flex', justifyContent: "flex-start", flexWrap: "wrap", marginTop: "15px" }}><b>12. Coroborarea conținutului disciplinei cu așteptările reprezentanților angajatorilor și asociațiilor profesionale reprezentative din domeniul aferent programului, precum și cu stadiul actual al cunoașterii în domeniul științific abordat și practicile în instituții de învățământ superior din Spațiul European al Învățământului Superior (SEÎS)</b></span></Col></Row>
          <div style={{
            border: "1px solid lightgrey", 
            borderRadius: "5px", 
            overflow: "hidden",
          }}>
            <Row gutter={16} style={{ borderBottom: "1px solid lightgrey" }}>
              <Col span={24} style={{ padding: "8px 16px" }}>
              <Form.Item
                name="subjectCoroboration"
              >
              <CustomEditor disabled={disabledByStatus} 
                            onEditorChange={(subjectCoroboration: string) => {
                                form.setFieldsValue({
                                    ...form.getFieldsValue(),
                                    subjectCoroboration,
                                } as any);
                            }}
                        />
              </Form.Item>
              </Col>
            </Row>
          </div>
          <div className={styles.secondLine} style={{ marginTop: "10px" }}>
              <div className={styles.firstColumn} style={{ display: "flex", width: "250px", justifyContent: "space-between " }}>
                <ConfirmationModal
                  nameButton={t('internships.addInternshipForm.cancel')}
                  modalText={t('internships.cancelMessage')}
                  titleButton={''}
                  handleFunction={handleCancel}
                  iconButton={null}
                  shapeButton={''}
                  typeButton={''}
                  disabledProp={false}
                  styleProp={{ background: theme.green, color: theme.white, marginRight: '10%', marginTop: '0%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                />
                <ConfirmationModal
                  nameButton={subjectId ? t('internships.addInternshipForm.updateButton') : t('internships.addInternshipForm.saveButton')}
                  modalText={t('internships.saveMessage')}
                  titleButton={''}
                  handleFunction={handleSave}
                  iconButton={null}
                  shapeButton={''}
                  typeButton={''}
                  disabledProp={false}
                  styleProp={{ background: theme.secondColor, color: theme.white, marginRight: '10%', marginTop: '0%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px' }}
                />
              </div>
            </div>                    
        </Form>
      </div>
    </Layout>
    );
  }
};

export default SubjectForm;