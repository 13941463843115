import { useState } from "react";
import { Table, Input, Typography } from "antd";
import Layout from "../../../Containers/Layout";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getLaboratoryCapacity, getRoomData } from "../../../Requests/laboratory-data-requests";

const { Search } = Input;
const { Title } = Typography;

const columns = [
  {
    title: "Nr. crt.",
    dataIndex: "no",
    key: "no",
    width: 100,
  },
  {
    title: "Numar",
    dataIndex: "roomNumber",
    key: "roomNumber",
  },
  {
    title: "Nume",
    dataIndex: "roomName",
    key: "roomName",
  },
  {
    title: "Suprafață (mp)",
    dataIndex: "area",
    key: "area",
  },
  {
    title: "Capacitate",
    dataIndex: "capacity",
    key: "capacity",
  },
  {
    title: "Suprafață (mp) / Capacitate",
    dataIndex: "ratio",
    key: "ratio",
  },
];

const LaboratoriesTable = () => {
  const [searchText, setSearchText] = useState("");
  const { programId, schoolYear } = useParams<{ programId: string, schoolYear: string }>();

  const { data, isLoading, error } = useQuery(
    ['getRoomData', programId, schoolYear],
    async () => {
      const response = await getLaboratoryCapacity(programId, Number(schoolYear));
      return response;
    }
  );
  const roomCapacityData = data || [];

  const informaticLabs = roomCapacityData.filter((item) => item.type === "Informatic");
  const technologicLabs = roomCapacityData.filter((item) => item.type === "Technological");

  return (
    <Layout>
      <div>
        {informaticLabs.length > 0 && (
          <>
            <Title level={3} style={{ textAlign: "left" }}>Laborator informatic</Title>
            <Table
              columns={columns}
              dataSource={informaticLabs}
              bordered
              rowKey={(record) => `${record.type}-${record.no}`}
              pagination={false}
            />
          </>
        )}

        {technologicLabs.length > 0 && (
          <>
            <Title level={3} style={{ textAlign: "left" }}>Laborator tehnologic</Title>
            <Table
              columns={columns}
              dataSource={technologicLabs}
              bordered
              rowKey={(record) => `${record.type}-${record.no}`}
              pagination={false}
            />
          </>
        )}
      </div>
    </Layout>
  );
};

export default LaboratoriesTable;