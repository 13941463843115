/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LaboratoryRoomData
 */
export interface LaboratoryRoomData {
    /**
     * 
     * @type {string}
     * @memberof LaboratoryRoomData
     */
    type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LaboratoryRoomData
     */
    no?: number;
    /**
     * 
     * @type {string}
     * @memberof LaboratoryRoomData
     */
    roomNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LaboratoryRoomData
     */
    roomName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LaboratoryRoomData
     */
    area?: number;
    /**
     * 
     * @type {number}
     * @memberof LaboratoryRoomData
     */
    capacity?: number;
    /**
     * 
     * @type {number}
     * @memberof LaboratoryRoomData
     */
    ratio?: number;
}

export function LaboratoryRoomDataFromJSON(json: any): LaboratoryRoomData {
    return LaboratoryRoomDataFromJSONTyped(json, false);
}

export function LaboratoryRoomDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaboratoryRoomData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'no': !exists(json, 'no') ? undefined : json['no'],
        'roomNumber': !exists(json, 'roomNumber') ? undefined : json['roomNumber'],
        'roomName': !exists(json, 'roomName') ? undefined : json['roomName'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'capacity': !exists(json, 'capacity') ? undefined : json['capacity'],
        'ratio': !exists(json, 'ratio') ? undefined : json['ratio'],
    };
}

export function LaboratoryRoomDataToJSON(value?: LaboratoryRoomData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'no': value.no,
        'roomNumber': value.roomNumber,
        'roomName': value.roomName,
        'area': value.area,
        'capacity': value.capacity,
        'ratio': value.ratio,
    };
}

