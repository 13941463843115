/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EvaluationTypeEnum,
    EvaluationTypeEnumFromJSON,
    EvaluationTypeEnumFromJSONTyped,
    EvaluationTypeEnumToJSON,
} from './EvaluationTypeEnum';
import {
    IndicatorTypeEnum,
    IndicatorTypeEnumFromJSON,
    IndicatorTypeEnumFromJSONTyped,
    IndicatorTypeEnumToJSON,
} from './IndicatorTypeEnum';
import {
    SemesterTypeEnum,
    SemesterTypeEnumFromJSON,
    SemesterTypeEnumFromJSONTyped,
    SemesterTypeEnumToJSON,
} from './SemesterTypeEnum';
import {
    StudyYearEnum,
    StudyYearEnumFromJSON,
    StudyYearEnumFromJSONTyped,
    StudyYearEnumToJSON,
} from './StudyYearEnum';

/**
 * 
 * @export
 * @interface AddSubjectDTO
 */
export interface AddSubjectDTO {
    /**
     * 
     * @type {number}
     * @memberof AddSubjectDTO
     */
    orderNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof AddSubjectDTO
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddSubjectDTO
     */
    nameRO?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddSubjectDTO
     */
    nameEN?: string | null;
    /**
     * 
     * @type {IndicatorTypeEnum}
     * @memberof AddSubjectDTO
     */
    formativeCategory?: IndicatorTypeEnum;
    /**
     * 
     * @type {IndicatorTypeEnum}
     * @memberof AddSubjectDTO
     */
    subjectCategory?: IndicatorTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AddSubjectDTO
     */
    credits?: number;
    /**
     * 
     * @type {number}
     * @memberof AddSubjectDTO
     */
    courseWeeklyHours?: number;
    /**
     * 
     * @type {number}
     * @memberof AddSubjectDTO
     */
    labWeeklyHours?: number;
    /**
     * 
     * @type {number}
     * @memberof AddSubjectDTO
     */
    seminarWeeklyHours?: number;
    /**
     * 
     * @type {number}
     * @memberof AddSubjectDTO
     */
    projectWeeklyHours?: number;
    /**
     * 
     * @type {EvaluationTypeEnum}
     * @memberof AddSubjectDTO
     */
    evaluationType?: EvaluationTypeEnum;
    /**
     * 
     * @type {SemesterTypeEnum}
     * @memberof AddSubjectDTO
     */
    semester?: SemesterTypeEnum;
    /**
     * 
     * @type {StudyYearEnum}
     * @memberof AddSubjectDTO
     */
    studyYear?: StudyYearEnum;
    /**
     * 
     * @type {number}
     * @memberof AddSubjectDTO
     */
    planYear?: number;
    /**
     * 
     * @type {string}
     * @memberof AddSubjectDTO
     */
    studyProgramId?: string;
    /**
     * 
     * @type {string}
     * @memberof AddSubjectDTO
     */
    studyPlanId?: string;
    /**
     * 
     * @type {string}
     * @memberof AddSubjectDTO
     */
    departmentId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddSubjectDTO
     */
    courseCoordinatorIds?: Array<string> | null;
}

export function AddSubjectDTOFromJSON(json: any): AddSubjectDTO {
    return AddSubjectDTOFromJSONTyped(json, false);
}

export function AddSubjectDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddSubjectDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderNumber': !exists(json, 'orderNumber') ? undefined : json['orderNumber'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'nameRO': !exists(json, 'nameRO') ? undefined : json['nameRO'],
        'nameEN': !exists(json, 'nameEN') ? undefined : json['nameEN'],
        'formativeCategory': !exists(json, 'formativeCategory') ? undefined : IndicatorTypeEnumFromJSON(json['formativeCategory']),
        'subjectCategory': !exists(json, 'subjectCategory') ? undefined : IndicatorTypeEnumFromJSON(json['subjectCategory']),
        'credits': !exists(json, 'credits') ? undefined : json['credits'],
        'courseWeeklyHours': !exists(json, 'courseWeeklyHours') ? undefined : json['courseWeeklyHours'],
        'labWeeklyHours': !exists(json, 'labWeeklyHours') ? undefined : json['labWeeklyHours'],
        'seminarWeeklyHours': !exists(json, 'seminarWeeklyHours') ? undefined : json['seminarWeeklyHours'],
        'projectWeeklyHours': !exists(json, 'projectWeeklyHours') ? undefined : json['projectWeeklyHours'],
        'evaluationType': !exists(json, 'evaluationType') ? undefined : EvaluationTypeEnumFromJSON(json['evaluationType']),
        'semester': !exists(json, 'semester') ? undefined : SemesterTypeEnumFromJSON(json['semester']),
        'studyYear': !exists(json, 'studyYear') ? undefined : StudyYearEnumFromJSON(json['studyYear']),
        'planYear': !exists(json, 'planYear') ? undefined : json['planYear'],
        'studyProgramId': !exists(json, 'studyProgramId') ? undefined : json['studyProgramId'],
        'studyPlanId': !exists(json, 'studyPlanId') ? undefined : json['studyPlanId'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'courseCoordinatorIds': !exists(json, 'courseCoordinatorIds') ? undefined : json['courseCoordinatorIds'],
    };
}

export function AddSubjectDTOToJSON(value?: AddSubjectDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderNumber': value.orderNumber,
        'code': value.code,
        'nameRO': value.nameRO,
        'nameEN': value.nameEN,
        'formativeCategory': IndicatorTypeEnumToJSON(value.formativeCategory),
        'subjectCategory': IndicatorTypeEnumToJSON(value.subjectCategory),
        'credits': value.credits,
        'courseWeeklyHours': value.courseWeeklyHours,
        'labWeeklyHours': value.labWeeklyHours,
        'seminarWeeklyHours': value.seminarWeeklyHours,
        'projectWeeklyHours': value.projectWeeklyHours,
        'evaluationType': EvaluationTypeEnumToJSON(value.evaluationType),
        'semester': SemesterTypeEnumToJSON(value.semester),
        'studyYear': StudyYearEnumToJSON(value.studyYear),
        'planYear': value.planYear,
        'studyProgramId': value.studyProgramId,
        'studyPlanId': value.studyPlanId,
        'departmentId': value.departmentId,
        'courseCoordinatorIds': value.courseCoordinatorIds,
    };
}

