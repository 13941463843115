import { useState } from "react";
import Layout from "../../../Containers/Layout";
import { Table, Input, Typography } from "antd";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getRoomData } from "../../../Requests/laboratory-data-requests";
import { getStudyProgram } from "../../../Requests/study-program-request";
const { Search } = Input;
const { Title } = Typography;


const LaboratoriesTable = () => {
  const [searchRoomNumber, setSearchRoomNumber] = useState<string>("");
  const [searchRoomName, setSearchRoomName] = useState<string>("");
  const { programId, schoolYear } = useParams<{ programId: string, schoolYear: string }>();

  const { data: program } = useQuery(
    ["getProgram", programId], 
    () => getStudyProgram(programId)
  );

  const { data, isLoading, error } = useQuery(
      ['getRoomData', programId, schoolYear],
      async () => {
          const response = await getRoomData(programId, Number(schoolYear));
          return response;
      }
  );
  const roomContentData = data || [];

  // Flatten data and add room grouping
  const flattenedData = roomContentData
    .map((roomData) =>
      roomData.disciplines?.map((discipline, index) => ({
        key: `${roomData.roomNumber}-${index}`,
        roomNumber: roomData.roomNumber,
        roomName: roomData.roomName,
        disciplinaName: discipline.disciplineName,
        laboratoryWorks: discipline.laboratoryWorks?.join(", "),
        isFirstRow: index === 0,
        roomRowSpan: roomData.disciplines?.length,
      }))
    )
    .flat()
    .filter((item): item is NonNullable<typeof item> => item !== undefined);

  const filteredData = flattenedData.filter((item) => {
    const matchesRoomNumber = !searchRoomNumber || item?.roomNumber?.toLowerCase().includes(searchRoomNumber.toLowerCase());
    const matchesRoomName = !searchRoomName || item?.roomName?.toLowerCase().includes(searchRoomName.toLowerCase());
    return matchesRoomNumber && matchesRoomName;
  });

  const columns = [
    {
      title: <Search placeholder="Numar" allowClear onSearch={setSearchRoomNumber} />,
      dataIndex: "roomNumber",
      key: "roomNumber",
      render: (text: string, record: any, index: number) => {
        const rowSpan = record.isFirstRow ? record.roomRowSpan : 0;
        return {
          children: text,
          props: { rowSpan },
        };
      },
    },
    {
      title: <Search placeholder="Nume" allowClear onSearch={setSearchRoomName} />,
      dataIndex: "roomName",
      key: "roomName",
      render: (text: string, record: any, index: number) => {
        const rowSpan = record.isFirstRow ? record.roomRowSpan : 0;
        return {
          children: text,
          props: { rowSpan },
        };
      },
    },
    {
      title: "Nume disciplina",
      dataIndex: "disciplinaName",
      key: "disciplinaName",
    },
    {
      title: "Lucrari de laborator",
      dataIndex: "laboratoryWorks",
      key: "laboratoryWorks",
      render: (works: string, record: any) => {
        const rowSpan = record.isFirstRow ? record.roomRowSpan : 0;
        const equipmentList = (
          <ul>
            {works.split(", ").map((work, index) => (
              <li key={index}>{work}</li>
            ))}
          </ul>
        );
        return {
          children: equipmentList,
        };
      },
    },
  ];

  return (
    <Layout>
      <Title level={3} style={{ textAlign: "left" }}>Continut laboratoare - {program?.name}</Title>
      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={false}
        bordered
      />
    </Layout>
  );
};

export default LaboratoriesTable;