/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EquipmentRoomData,
    EquipmentRoomDataFromJSON,
    EquipmentRoomDataToJSON,
    LaboratoryRoomData,
    LaboratoryRoomDataFromJSON,
    LaboratoryRoomDataToJSON,
    PlanLaboratoryDto,
    PlanLaboratoryDtoFromJSON,
    PlanLaboratoryDtoToJSON,
    RoomData,
    RoomDataFromJSON,
    RoomDataToJSON,
} from '../models';

export interface ApiLaboratoriesDataCapacityStudyProgramIdSchoolYearGetRequest {
    studyProgramId: string;
    schoolYear: number;
}

export interface ApiLaboratoriesDataContentStudyProgramIdSchoolYearGetRequest {
    studyProgramId: string;
    schoolYear: number;
}

export interface ApiLaboratoriesDataEquipmentStudyProgramIdSchoolYearGetRequest {
    studyProgramId: string;
    schoolYear: number;
}

export interface ApiLaboratoriesDataLaboratoriesStudyProgramIdSchoolYearGetRequest {
    studyProgramId: string;
    schoolYear: number;
}

/**
 * 
 */
export class LaboratoriesDataApi extends runtime.BaseAPI {

    /**
     */
    async apiLaboratoriesDataCapacityStudyProgramIdSchoolYearGetRaw(requestParameters: ApiLaboratoriesDataCapacityStudyProgramIdSchoolYearGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<LaboratoryRoomData>>> {
        if (requestParameters.studyProgramId === null || requestParameters.studyProgramId === undefined) {
            throw new runtime.RequiredError('studyProgramId','Required parameter requestParameters.studyProgramId was null or undefined when calling apiLaboratoriesDataCapacityStudyProgramIdSchoolYearGet.');
        }

        if (requestParameters.schoolYear === null || requestParameters.schoolYear === undefined) {
            throw new runtime.RequiredError('schoolYear','Required parameter requestParameters.schoolYear was null or undefined when calling apiLaboratoriesDataCapacityStudyProgramIdSchoolYearGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/LaboratoriesData/capacity/{studyProgramId}/{schoolYear}`.replace(`{${"studyProgramId"}}`, encodeURIComponent(String(requestParameters.studyProgramId))).replace(`{${"schoolYear"}}`, encodeURIComponent(String(requestParameters.schoolYear))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LaboratoryRoomDataFromJSON));
    }

    /**
     */
    async apiLaboratoriesDataCapacityStudyProgramIdSchoolYearGet(requestParameters: ApiLaboratoriesDataCapacityStudyProgramIdSchoolYearGetRequest, initOverrides?: RequestInit): Promise<Array<LaboratoryRoomData>> {
        const response = await this.apiLaboratoriesDataCapacityStudyProgramIdSchoolYearGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiLaboratoriesDataContentStudyProgramIdSchoolYearGetRaw(requestParameters: ApiLaboratoriesDataContentStudyProgramIdSchoolYearGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<RoomData>>> {
        if (requestParameters.studyProgramId === null || requestParameters.studyProgramId === undefined) {
            throw new runtime.RequiredError('studyProgramId','Required parameter requestParameters.studyProgramId was null or undefined when calling apiLaboratoriesDataContentStudyProgramIdSchoolYearGet.');
        }

        if (requestParameters.schoolYear === null || requestParameters.schoolYear === undefined) {
            throw new runtime.RequiredError('schoolYear','Required parameter requestParameters.schoolYear was null or undefined when calling apiLaboratoriesDataContentStudyProgramIdSchoolYearGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/LaboratoriesData/content/{studyProgramId}/{schoolYear}`.replace(`{${"studyProgramId"}}`, encodeURIComponent(String(requestParameters.studyProgramId))).replace(`{${"schoolYear"}}`, encodeURIComponent(String(requestParameters.schoolYear))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoomDataFromJSON));
    }

    /**
     */
    async apiLaboratoriesDataContentStudyProgramIdSchoolYearGet(requestParameters: ApiLaboratoriesDataContentStudyProgramIdSchoolYearGetRequest, initOverrides?: RequestInit): Promise<Array<RoomData>> {
        const response = await this.apiLaboratoriesDataContentStudyProgramIdSchoolYearGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiLaboratoriesDataEquipmentStudyProgramIdSchoolYearGetRaw(requestParameters: ApiLaboratoriesDataEquipmentStudyProgramIdSchoolYearGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<EquipmentRoomData>>> {
        if (requestParameters.studyProgramId === null || requestParameters.studyProgramId === undefined) {
            throw new runtime.RequiredError('studyProgramId','Required parameter requestParameters.studyProgramId was null or undefined when calling apiLaboratoriesDataEquipmentStudyProgramIdSchoolYearGet.');
        }

        if (requestParameters.schoolYear === null || requestParameters.schoolYear === undefined) {
            throw new runtime.RequiredError('schoolYear','Required parameter requestParameters.schoolYear was null or undefined when calling apiLaboratoriesDataEquipmentStudyProgramIdSchoolYearGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/LaboratoriesData/equipment/{studyProgramId}/{schoolYear}`.replace(`{${"studyProgramId"}}`, encodeURIComponent(String(requestParameters.studyProgramId))).replace(`{${"schoolYear"}}`, encodeURIComponent(String(requestParameters.schoolYear))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EquipmentRoomDataFromJSON));
    }

    /**
     */
    async apiLaboratoriesDataEquipmentStudyProgramIdSchoolYearGet(requestParameters: ApiLaboratoriesDataEquipmentStudyProgramIdSchoolYearGetRequest, initOverrides?: RequestInit): Promise<Array<EquipmentRoomData>> {
        const response = await this.apiLaboratoriesDataEquipmentStudyProgramIdSchoolYearGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiLaboratoriesDataLaboratoriesStudyProgramIdSchoolYearGetRaw(requestParameters: ApiLaboratoriesDataLaboratoriesStudyProgramIdSchoolYearGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<PlanLaboratoryDto>>> {
        if (requestParameters.studyProgramId === null || requestParameters.studyProgramId === undefined) {
            throw new runtime.RequiredError('studyProgramId','Required parameter requestParameters.studyProgramId was null or undefined when calling apiLaboratoriesDataLaboratoriesStudyProgramIdSchoolYearGet.');
        }

        if (requestParameters.schoolYear === null || requestParameters.schoolYear === undefined) {
            throw new runtime.RequiredError('schoolYear','Required parameter requestParameters.schoolYear was null or undefined when calling apiLaboratoriesDataLaboratoriesStudyProgramIdSchoolYearGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/LaboratoriesData/laboratories/{studyProgramId}/{schoolYear}`.replace(`{${"studyProgramId"}}`, encodeURIComponent(String(requestParameters.studyProgramId))).replace(`{${"schoolYear"}}`, encodeURIComponent(String(requestParameters.schoolYear))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlanLaboratoryDtoFromJSON));
    }

    /**
     */
    async apiLaboratoriesDataLaboratoriesStudyProgramIdSchoolYearGet(requestParameters: ApiLaboratoriesDataLaboratoriesStudyProgramIdSchoolYearGetRequest, initOverrides?: RequestInit): Promise<Array<PlanLaboratoryDto>> {
        const response = await this.apiLaboratoriesDataLaboratoriesStudyProgramIdSchoolYearGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
