/* tslint:disable */
/* eslint-disable */
/**
 * Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Department,
    DepartmentFromJSON,
    DepartmentFromJSONTyped,
    DepartmentToJSON,
} from './Department';
import {
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';
import {
    StudyProgram,
    StudyProgramFromJSON,
    StudyProgramFromJSONTyped,
    StudyProgramToJSON,
} from './StudyProgram';
import {
    Subject,
    SubjectFromJSON,
    SubjectFromJSONTyped,
    SubjectToJSON,
} from './Subject';

/**
 * 
 * @export
 * @interface AcademicUser
 */
export interface AcademicUser {
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof AcademicUser
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AcademicUser
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AcademicUser
     */
    cursId?: number;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    image?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcademicUser
     */
    departmentId?: string;
    /**
     * 
     * @type {Department}
     * @memberof AcademicUser
     */
    department?: Department;
    /**
     * 
     * @type {Array<Role>}
     * @memberof AcademicUser
     */
    roles?: Array<Role> | null;
    /**
     * 
     * @type {Array<Subject>}
     * @memberof AcademicUser
     */
    coordinatedCourses?: Array<Subject> | null;
    /**
     * 
     * @type {Array<Subject>}
     * @memberof AcademicUser
     */
    coordinatedLabs?: Array<Subject> | null;
    /**
     * 
     * @type {Array<StudyProgram>}
     * @memberof AcademicUser
     */
    programs?: Array<StudyProgram> | null;
}

export function AcademicUserFromJSON(json: any): AcademicUser {
    return AcademicUserFromJSONTyped(json, false);
}

export function AcademicUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcademicUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'cursId': !exists(json, 'cursId') ? undefined : json['cursId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'department': !exists(json, 'department') ? undefined : DepartmentFromJSON(json['department']),
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] === null ? null : (json['roles'] as Array<any>).map(RoleFromJSON)),
        'coordinatedCourses': !exists(json, 'coordinatedCourses') ? undefined : (json['coordinatedCourses'] === null ? null : (json['coordinatedCourses'] as Array<any>).map(SubjectFromJSON)),
        'coordinatedLabs': !exists(json, 'coordinatedLabs') ? undefined : (json['coordinatedLabs'] === null ? null : (json['coordinatedLabs'] as Array<any>).map(SubjectFromJSON)),
        'programs': !exists(json, 'programs') ? undefined : (json['programs'] === null ? null : (json['programs'] as Array<any>).map(StudyProgramFromJSON)),
    };
}

export function AcademicUserToJSON(value?: AcademicUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'name': value.name,
        'cursId': value.cursId,
        'email': value.email,
        'phone': value.phone,
        'image': value.image,
        'departmentId': value.departmentId,
        'department': DepartmentToJSON(value.department),
        'roles': value.roles === undefined ? undefined : (value.roles === null ? null : (value.roles as Array<any>).map(RoleToJSON)),
        'coordinatedCourses': value.coordinatedCourses === undefined ? undefined : (value.coordinatedCourses === null ? null : (value.coordinatedCourses as Array<any>).map(SubjectToJSON)),
        'coordinatedLabs': value.coordinatedLabs === undefined ? undefined : (value.coordinatedLabs === null ? null : (value.coordinatedLabs as Array<any>).map(SubjectToJSON)),
        'programs': value.programs === undefined ? undefined : (value.programs === null ? null : (value.programs as Array<any>).map(StudyProgramToJSON)),
    };
}

