import { useState } from "react";
import Layout from "../../../Containers/Layout";
import { Table, Input, Typography } from "antd";
import { useQuery } from "react-query";
import { getLaboratories } from "../../../Requests/laboratory-data-requests";
import { useParams } from "react-router-dom";
import { getStudyProgram } from "../../../Requests/study-program-request";
import { LaboratoryCategoryEnum } from "../../../Api";
const { Title } = Typography;
const { Search } = Input;

const LaboratoriesTable = () => {
    const [searchID, setSearchID] = useState('');
    const [searchNumber, setSearchNumber] = useState('');
    const [searchName, setSearchName] = useState('');
    const { programId, schoolYear } = useParams<{ programId: string, schoolYear: string }>();

    const { data, isLoading, error } = useQuery(
        ['getLaboratories', programId, schoolYear],
        async () => {
            const response = await getLaboratories(programId, Number(schoolYear));
            return response;
        }
    );

    const { data: program } = useQuery(
        ["getProgram", programId], 
        () => getStudyProgram(programId)
      );

    const laboratoriesData = data ? data : [];

    const filteredData = laboratoriesData.filter((item) => {
        const matchesNumber = searchNumber ? item.number?.toLowerCase().includes(searchNumber.toLowerCase()) : true;
        const matchesName = searchName ? item.name?.toLowerCase().includes(searchName.toLowerCase()) : true;
        return matchesNumber && matchesName;
    });

    const columns = [
        {
            title: <Search placeholder="Numar" allowClear onSearch={setSearchNumber} />,
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: <Search placeholder="Nume" allowClear onSearch={setSearchName} />,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: "Categorie",
            dataIndex: 'category',
            key: 'category',
            render: (category: LaboratoryCategoryEnum) => (
                category === LaboratoryCategoryEnum.Technological ? "Tehnologic" : "Informatic"
            ),
        },
        {
            title: "Suprafata",
            dataIndex: 'area',
            key: 'area',
        },
        {
            title: "Capacitate",
            dataIndex: 'capacity',
            key: 'capacity',
        },
        {
            title: "Discipline",
            dataIndex: 'disciplines',
            key: 'disciplines',
            render: (disciplines: string[]) => (
                disciplines.length > 0
                    ? (
                        <div>
                            {disciplines.map((discipline, index) => (
                                <Typography.Text key={index} style={{ display: 'block' }}>
                                    {discipline}
                                </Typography.Text>
                            ))}
                        </div>
                    )
                    : "N/A"
            ),
        },
    ];

    return (
        <Layout>
            <div style={{ padding: '20px' }}>
                {isLoading && <div>Loading...</div>}
                {error && <div>Error loading data</div>}
                <Title level={3} style={{ textAlign: "left" }}>Laboratoare - {program?.name}</Title>
                <Table
                    dataSource={filteredData || []}
                    columns={columns}
                    rowKey="id"
                    locale={{
                        emptyText: "No data available",
                    }}
                    pagination={false}
                />
            </div>
        </Layout>
    );
};

export default LaboratoriesTable;